import request from '@/utils/request'

//---------商品类目---------
//查询类目
export function queryCategory(data) {
  return request({
    url: '/MA_mall_category/queryCategory',
    method: 'get',
    params: data
  })
}

//删除类目
export function deleteCategory(data) {
  return request({
    url: '/MA_mall_category/delete',
    method: 'get',
    params: data
  })
}

//修改或新增类目
export function addOrModify(data) {
  return request({
    url: '/MA_mall_category/addOrModify',
    method: 'POST',
    data: data
  })
}


//图片、附件上传（返回id）
export function upload(form, data) {
	return request({
		url: '/fileutil/Miniupload',
		method: 'post',
		params: data,
		data: form
	})
}

//图片、附件上传（返回url）
export function upLoadFileObj(form, data) {
	return request({
		url: '/fileutil/MiniuploadObj',
		method: 'post',
		params: data,
		data: form
	})
}


