import request from '@/utils/request'

//-------------促销管理---------
//获取促销
export function promotionDetail(data) {
  return request({
    url: '/MA_mall_promotion/get',
    method: 'get',
    params: data
  })
}

//创建促销
export function promotionCreate(data) {
  return request({
    url: '/MA_mall_promotion/create',
    method: 'post',
    data: data
  })
}

//更新促销
export function promotionUpdate(data) {
  return request({
    url: '/MA_mall_promotion/update',
    method: 'post',
    data: data
  })
}

