import request from '@/utils/request'

//-------------商品管理---------
//列表
export function goodsList(data) {
  return request({
    url: '/MA_mall_goods/list',
    method: 'post',
    params: data
  })
}
//审核列表
export function checklist(data) {
  return request({
    url: '/MA_mall_goods/checklist',
    method: 'post',
    params: data
  })
}

//创建商品
export function goodsCreate(data) {
  return request({
    url: '/MA_mall_goods/create',
    method: 'post',
    data: data
  })
}

//更新商品
export function goodsUpdate(data) {
  return request({
    url: '/MA_mall_goods/update',
    method: 'post',
    data: data
  })
}

//退货
export function returnOfGoodsFn(data){
  return request({
    url: '/MA_mall_goods/cancelOrder',
    method: 'get',
    params: data
  })
}

//删除
export function goodsDelete(data) {
  return request({
    url: '/MA_mall_goods/delete',
    method: 'get',
    params: data
  })
}

//商品详情
export function goodsDetail(data) {
  return request({
    url: '/MA_mall_goods/detail',
    method: 'get',
    params: data
  })
}
