<template>
    <div class="wrapper">
        <div class="content">
            <el-form :model="form" :rules="rules" ref="form" label-width="150px">
                <el-form-item label="活动名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入兑换活动名称"></el-input>
                </el-form-item>
                <el-form-item label="活动商品" prop="ids">
                    <el-select v-model="form.ids" :multiple="true" filterable placeholder="请选择活动商品" @change="selectChange" style="width: 100%;">
                        <el-option v-for="item in goodsList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>

                    <el-table :data="form.goods" border style="width: 100%;margin-top: 20px;">
                        <el-table-column prop="id" label="" v-if="false"></el-table-column>
                        <el-table-column prop="name" label="商品名称"></el-table-column>
                        <!-- <el-table-column prop="goods[0].specifications" :formatter="format_specifications" label="规格"></el-table-column> -->
                        <el-table-column prop="retail_price" label="消费积分"></el-table-column>
                        <el-table-column prop="price" label="限兑积分">
                            <template slot-scope="scope">
                                <el-form-item :prop="'goods.' + scope.$index + '.price'">
                                    <el-input v-model="scope.row.price" placeholder="请输入限兑积分"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button @click="deleteRow(scope.row)" type="danger" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item label="开始时间" prop="begin_time">
                    <el-date-picker v-model="form.begin_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择活动开始时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="截止时间" prop="end_time">
                    <el-date-picker v-model="form.end_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择活动截止时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="背景图" prop="bg_pic">
                    <el-upload
                        action="#"
                        :class="{bghide: bghideUploadEidt}"
                        :auto-upload="false"
                        accept="image/*"
                        :show-file-list="true"
                        list-type="picture-card"
                        :on-change="handleChange"
                        :on-progress="imgloading"
                        :file-list="picList"
                        >
                        <div slot="file" slot-scope="{file}">
                            <img :src="file.url"  style="width:100%;height:146px !important">
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview" @click="bigImg(file)"><i class="el-icon-zoom-in"></i></span>
                                <span class="el-upload-list__item-delete" @click="handleRemove(file)" v-show="!viewFlag">
                                <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('form')">提交</el-button>
                    <el-button @click="resetForm('form')">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 图片大图预览 -->
        <el-dialog :visible.sync="imgDialogVisible" title="图片预览" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>
import { promotionDetail, promotionCreate, promotionUpdate } from '@/api/promotion'
import { goodsList } from '@/api/goodsManage'
import { upLoadFileObj } from '@/api/itemCategory'
export default {
    name: 'promotion',
    components:{},
    props:{},
    data() {
        return {
            promotionData: {},
            createFlag: true,
            goodsList: [],
            checkedData: [],
            dialogImageUrl: '',
            dialogVisible: false,
            fileList: [],
            form: {
                name: '',
                ids: [],
                begin_time: '',
                bg_pic: '',
                end_time: '',
                items: [],
                goods: [],
            },
            rules: {
                name: [
                    { required: true, message: '请输入兑换活动名称', trigger: 'blur' }
                ],
                ids: [
                    { required: true, message: '请选择活动商品', trigger: 'change' }
                ],
                begin_time: [
                    { required: true, message: '请选择活动开始时间', trigger: 'change' }
                ],
                end_time: [
                    { required: true, message: '请选择活动截止时间', trigger: 'change' }
                ],
                bg_pic: [
                    { required: true, message: '请选择活动背景图片', trigger: 'change' }
                ],
            },
            bghideUploadEidt: false,
            viewFlag: false,
            imgDialogVisible: false,
            picList: [],
        }
    },
    watch:{},
    created(){},
    mounted(){
        this.getPromotionData();
        this.getGoods();
    },
    computed:{},
    methods: {
        getPromotionData() {
            promotionDetail({}).then( res => {
                if(res.result == "200") {
                    let detail = res.detail;
                    if(detail) {
                        this.promotionData = res.detail;
                        this.createFlag = res.detail.id ? false : true;
                        this.form = {
                            name: detail.name,
                            begin_time: detail.begin_time,
                            bg_pic: detail.bg_pic,
                            end_time: detail.end_time,
                            items: detail.items,
                        }
                        this.picList = [];
                        this.picList.push({
                            name: 'goodsface',
                            url: this.form.bg_pic
                        })
                        this.bghideUploadEidt = true;
                        let items = res.detail.items;
                        let ids = [], goods = [];
                        if(items.length > 0) {
                            for(let i = 0; i < items.length; i++) {
                                ids[i] = items[i].product_id;
                                goods[i] = {
                                    id: items[i].product_id,
                                    name: items[i].product_name,
                                    retail_price: items[i].nomal_price,
                                    price: items[i].price,
                                    goods: [{
                                        specifications: items[i].specifications,
                                    }]
                                }
                            }
                            this.form.ids = ids;
                            this.form.goods = goods;
                        } else {
                            this.form.ids = [];
                            this.form.goods = [];
                        }
                    }
                } else  {
                    this.$message.warning(res.description);
                }
            });
        },
        // 获取商品
        getGoods() {
            goodsList({
                pageSize: 1000,
            }).then( res => {
                if(res.result == "200") {
                    let goodsList = res.detail.list;
                    goodsList.forEach(item => {
                        item.price = null;
                    });
                    this.goodsList = goodsList;
                }
            })
        },
        selectChange(value) {
            let checkedData = [];
            value.map( index => {
                checkedData.push(...this.goodsList.filter(item => item.id == index));
            });
            let preData = this.form.goods;
            checkedData.map(item => {
                preData.map(pre => {
                    if(item.id == pre.id) {
                        item.price = pre.price;
                    }
                })
            });
            let idArr = [];
            checkedData.forEach((item1, index1) => {
                let checkBool = false
                preData.forEach((item2, index2) => {
                    if (item1.id=== item2.id) {
                        checkBool = true
                    }
                })
                if (!checkBool) {
                    idArr.push(item1);
                }
            })
            if(idArr.length > 0) {
                this.goodsList.map(item => {
                    if(item.id == idArr[0].id) {
                        item.price = null;
                    }
                });
            }
            this.checkedData = checkedData;
            this.form.goods = checkedData;
            this.$forceUpdate();
        },
        // 删除活动商品
        deleteRow(row) {
            let checkedData = this.form.goods.filter(item => item.id != row.id);
            this.form.ids = this.form.ids.filter(item => item != row.id);
            this.checkedData = checkedData;
            console.log("row:", row);
            console.log("goods:", this.goodsList);
            // this.form.goods[0].price = '';
            this.form.goods = checkedData;
            console.log("checkedData:", checkedData);
            this.$forceUpdate();
        },
        format_specifications(row, column) {
            // let arr = '';
            return row.goods[0].specifications;
        },
        //图片预览或附件下载
        bigImg(file) {
            // console.log(file)
            //通过id获取图片或链接地址
            let url = file.url;
            this.dialogImageUrl = url;
            this.imgDialogVisible = true;
        },
        //背景图上传
        handleChange(file, fileList) { 
            this.bghideUploadEidt = fileList.length>0;
            let formData = new FormData()
                formData.append('file', file.raw);
            upLoadFileObj(formData, {
                userid: this.GLOBAL.adminId(),
                contentType: 'image/*'
            }).then((res)=>{
                if(res.result==200){
                    this.$message.success('图片上传成功')
                    let url = res.detail[0].path;  
                    this.picList.push({name:res.detail[0].id, url})
                    this.bghideUploadEidt = true;
                    this.form.bg_pic = url;
                }else{
                    this.$message.error('图片上传失败')
                }
            })
        }, 
        //上传图片中
        imgloading(event) {
            console.log(event)
            this.$message.danger("文件上传中请勿重复上传");
        },
        //背景图删除
        handleRemove(file) {
            this.picList.map((item,index)=>{
                if(item.name==file.name){
                    this.picList.splice(index,1)
                }
            })
            this.form.bg_pic = '';
            this.bghideUploadEidt = this.picList.length>0
        }, 
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    var validFlag = false;
                    this.form.goods.map(item => validFlag =  item.price ? true : false);
                    if(validFlag) {
                        this.submitData();
                    } else {
                        this.$message.warning("请输入限兑积分")
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        submitData() {
            let _this = this;
            this.form.items = this.checkedData;
            let data = {},
                items = [];
            if(_this.createFlag) {
                this.form.goods.forEach((item,index) => {
                    items[index] = {
                        GOODS_ID: item.goods[0].id,
                        PRODUCT_ID: item.id,
                        price: item.price
                    }
                });
                data = {
                    name: this.form.name,
                    BEGIN_TIME: this.form.begin_time,
                    end_time: this.form.end_time,
                    bg_pic: this.form.bg_pic,
                    items: items
                }
                console.log("this.form:",this.form);
                promotionCreate(data).then( res => {
                    if(res.result == '200') {
                        this.$message.success('创建成功');
                    } else {
                        this.$message.warning(res.description)
                    }
                })
            } else {
                this.form.goods.forEach((item,index) => {
                    items[index] = {
                        GOODS_ID: item.goods[0].id,
                        PRODUCT_ID: item.id,
                        price: item.price
                    }
                });
                data = {
                    id: this.promotionData.id,
                    name: this.form.name,
                    BEGIN_TIME: this.form.begin_time,
                    end_time: this.form.end_time,
                    bg_pic: this.form.bg_pic,
                    items: items
                }
                promotionUpdate(data).then( res => {
                    if(res.result == '200') {
                        this.$message.success('修改成功');
                    } else {
                        this.$message.warning(res.description)
                    }
                })
            }
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    },
}
</script>
<style lang="scss" scoped>
    .wrapper {
        padding: 40px;
    }
    // 隐藏上传按钮
  ::v-deep {
    .bghide{
      &>div.el-upload--picture-card {
        display: none;
      }
    }
  }
</style>